.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-top-menu{
  height: 15vh;
  border: 1px solid gray;
  position: relative;
}
.App-input-placeholder{
  /* height: 7vh;
  min-height: 40px; */
  height: 40px;
  border: 1px solid gray;
  position: relative;
  border-top: none;
  border-bottom: none;
  background-color: navajowhite;
}
.App-title-placeholder{
  min-height: 100px;
  height: 15vh;
  border: 1px solid gray;
  position: relative;
}
.App-content-placeholder{
  height: 67vh;
  border: 1px solid gray;
  /* overflow: scroll; */
}

.nakStatus{
  position: absolute;
  top: 10px;
  left: 10px;
  border: 1px solid white;
  font-weight: 600;
  padding: 4px 7px;
  border-radius: 5px;
}

.nakStatusRight{
  position: absolute;
  top: 10px;
  right: 10px;
  border: 1px solid white;
  font-weight: 600;
  padding: 4px 7px;
  border-radius: 5px;
}

.App-input{
  width: 100%;
  
}

.fs-6{
 font-size: 0.9em !important; 
}

.fs-7{
 font-size: 0.8em !important; 
}

.fs-8{
 font-size: 0.7em !important; 
}

.fs-9{
 font-size: 0.6em !important; 
}

.fs-10{
 font-size: 0.5em !important; 
}
/* 
@media (min-width: 557px) AND (max-width: 991px){
  .App-top-menu{
    height: 10vh;
    border: 1px solid gray;
  }
  .App-title-placeholder{
    height: 30vh;
    border: 1px solid gray;
  }
  .App-content-placeholder{
    height: 55vh;
    border: 1px solid gray;
  }
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
